import { cn } from '#app/utils/misc.tsx'

export function Gradient({
	className,
	...props
}: React.ComponentPropsWithoutRef<'div'>) {
	return (
		<div
			{...props}
			className={cn(
				className,
				'dark:via-[#8752a3 bg-[linear-gradient(115deg,var(--tw-gradient-stops))] from-[#d3eef4] from-[28%] via-[#f1eec8] via-[70%] to-[#D8B5FF] dark:bg-[linear-gradient(115deg,var(--tw-gradient-stops))] dark:from-[#5c73b9] dark:from-[28%] dark:via-[70%] dark:to-[#614385] sm:bg-[linear-gradient(145deg,var(--tw-gradient-stops))] dark:sm:bg-[linear-gradient(145deg,var(--tw-gradient-stops))]',
			)}
		/>
	)
}

export function GradientBackground() {
	return (
		<div className="relative mx-auto max-w-7xl">
			<div
				className={cn(
					'absolute -right-60 -top-44 h-60 w-[36rem] transform-gpu md:right-0',
					'bg-[linear-gradient(115deg,var(--tw-gradient-stops))] from-[#fff1be] from-[28%] via-[#ee87cb] via-[70%] to-[#b060ff]',
					'rotate-[-10deg] rounded-full blur-3xl',
				)}
			/>
		</div>
	)
}
