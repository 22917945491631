import { Form, Link, useRouteLoaderData, useSubmit } from '@remix-run/react'
import { useRef } from 'react'
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuPortal,
	DropdownMenuTrigger,
} from '#app/components/ui/dropdown-menu.tsx'
import { Icon, href as iconsHref } from '#app/components/ui/icon.tsx'
import { ThemeSwitch } from '#app/routes/resources+/theme-switch.tsx'
import { useOptionalUser, useUser } from '#app/utils/user.ts'
import { type loader } from '#app/root.tsx'
import {
	PlusGrid,
	PlusGridRow,
	PlusGridItem,
} from '#app/components/ui/plus-grid.tsx'

const links = [
	// { href: '/pricing', label: 'Pricing' },
	// { href: '/features', label: 'Features' },
	// { href: '/about', label: 'About' },
]

export default function Header() {
	const user = useOptionalUser()
	const data = useRouteLoaderData<typeof loader>('root')

	//console.log(data)

	return (
		<header className="relative py-6">
			<div className="container relative">
				<PlusGrid>
					<PlusGridRow className="relative flex justify-between">
						<div className="relative flex gap-6">
							<PlusGridItem className="py-3">
								<Logo />
							</PlusGridItem>
							{/* {banner && (
								<div className="relative hidden items-center py-3 lg:flex">
									{banner}
								</div>
							)} */}
						</div>
						<DesktopNav />
						{/* <MobileNavButton />  */}
					</PlusGridRow>
				</PlusGrid>
			</div>
		</header>
	)
}

function Logo() {
	return (
		<div className="flex items-center gap-2 text-xl font-bold">
			<Link to="/" className="">
				<svg
					width="555"
					height="40"
					viewBox="0 0 555 40"
					className="w-56 overflow-hidden lg:w-96"
				>
					<g>
						<path
							d="M0,18.8352C0,7.7866,5.7347,0,15.4154,0c6.7344,0,12.1534,3.525,13.7844,10.5751h-2.4728c-1.5784-5.4717-5.9452-8.1549-11.2064-8.1549C7.2605,2.4202,2.6306,9.1545,2.6306,18.8352c0,9.8385,4.6825,16.415,12.89,16.415,5.4191,0,9.7333-2.7885,11.6799-8.8915h2.578c-1.9467,7.5236-7.3131,11.3116-14.3632,11.3116C5.7347,37.6704,0,30.0942,0,18.8352Z"
							className="#3e3d40 dark:fill-white"
							fill="#3e3d40"
						/>
						<path
							d="M33.8773,18.8352c0-11.4695,6.2083-18.8352,15.9415-18.8352,9.6807,0,15.8889,7.3657,15.8889,18.8352s-6.2083,18.8352-15.8889,18.8352c-9.7333,0-15.9415-7.3657-15.9415-18.8352ZM63.0771,18.8352c0-10.1016-5.1034-16.415-13.2583-16.415-8.2075,0-13.3109,6.3135-13.3109,16.415s5.156,16.415,13.3109,16.415,13.2583-6.3135,13.2583-16.415Z"
							className="#3e3d40 dark:fill-white"
							fill="#3e3d40"
						/>
						<path
							d="M104.0045.3683v36.8812h-2.5254v-15.0997l.0526-15.468-13.2583,19.5718h-.3157l-13.2057-19.4666v30.4625h-2.5254V.3683h.9996l14.9419,21.8867L103.0575.3683h.947Z"
							className="#3e3d40 dark:fill-white"
							fill="#3e3d40"
						/>
						<path
							d="M135.6193,12.1534c0,7.2605-4.5773,11.7852-12.0482,11.7852h-8.5232v13.3109h-2.5254V.4209h11.0486c7.4709,0,12.0482,4.5247,12.0482,11.7325ZM133.0413,12.2061c0-5.9452-3.4724-9.4176-9.4702-9.4176h-8.5232v18.7826h8.5232c5.9978,0,9.4702-3.4724,9.4702-9.365Z"
							className="#3e3d40 dark:fill-white"
							fill="#3e3d40"
						/>
						<path
							d="M159.6587,34.8293v2.4202h-18.3617V.4209h18.1512v2.4202h-15.6259v14.1001h14.9945v2.3676h-14.9945v15.5206h15.8363Z"
							fill="#3e3d40"
							className="#3e3d40 dark:fill-white"
						/>
						<path
							d="M186.1191,2.8411h-10.2594v34.4084h-2.5254V2.8411h-10.2068V.4209h22.9916v2.4202Z"
							fill="#3e3d40"
							className="#3e3d40 dark:fill-white"
						/>
						<path
							d="M191.1139.4209h2.5254v36.8286h-2.5254V.4209Z"
							fill="#3e3d40"
							className="#3e3d40 dark:fill-white"
						/>
						<path
							d="M221.6799,2.8411h-10.2594v34.4084h-2.5254V2.8411h-10.2068V.4209h22.9916v2.4202Z"
							fill="#3e3d40"
							className="#3e3d40 dark:fill-white"
						/>
						<path
							d="M223.2029,18.8352c0-11.4695,6.2083-18.8352,15.9415-18.8352,9.6807,0,15.8889,7.3657,15.8889,18.8352s-6.2083,18.8352-15.8889,18.8352c-9.7333,0-15.9415-7.3657-15.9415-18.8352ZM252.4027,18.8352c0-10.1016-5.1034-16.415-13.2583-16.415-8.2075,0-13.3109,6.3135-13.3109,16.415s5.156,16.415,13.3109,16.415,13.2583-6.3135,13.2583-16.415Z"
							fill="#3e3d40"
							className="#3e3d40 dark:fill-white"
						/>
						<path
							d="M285.7013,37.0917v.1578h-2.7885l-9.5228-14.4158h-9.3124v14.4158h-2.5254V.4209h11.5221c7.1027,0,11.259,4.1038,11.259,11.1012,0,5.9452-3.1567,10.049-8.2601,11.0486l9.6281,14.521ZM264.0776,20.4662h9.2072c5.3665,0,8.4706-3.3672,8.4706-8.8915,0-5.7347-3.0515-8.7863-8.7336-8.7863h-8.9441v17.6777Z"
							fill="#3e3d40"
							className="#3e3d40 dark:fill-white"
						/>
					</g>
					<g>
						<path
							d="M323.6862,27.6324c0,6.3925-4.2617,10.6542-11.1738,10.6542h-14.604V1.9066h13.2007c6.9122,0,11.3818,4.1577,11.3818,10.0825,0,2.4427-1.0396,5.3531-3.3782,7.1201,3.1702,1.923,4.5735,5.1971,4.5735,8.5233ZM310.3815,10.0141h-3.5861v6.3405h3.5861c1.871,0,3.0143-1.1954,3.0143-3.2222,0-1.923-1.1433-3.1183-3.0143-3.1183ZM314.4872,26.8008c0-2.0789-1.1953-3.2742-3.2222-3.2742h-4.4696v6.7043h4.4696c2.0269,0,3.2222-1.3513,3.2222-3.4302Z"
							fill="#3e3d40"
							className="#3e3d40 dark:fill-white"
						/>
						<path
							d="M356.7914,37.7149v.5717h-9.4067l-1.6631-4.6774h-10.1864l-1.6631,4.6774h-9.3549v-.5717l14.0842-36.1721h4.1577l14.0322,36.1721ZM338.2377,25.6055h4.7814l-2.3387-8.8871-2.4426,8.8871Z"
							fill="#3e3d40"
							className="#3e3d40 dark:fill-white"
						/>
						<path
							d="M357.051,20.0966c0-11.3818,6.0807-18.7097,15.5914-18.7097,8.4714,0,14.0842,4.9373,15.2277,13.9283h-8.7832c-.5717-3.2742-3.0663-5.3531-6.2366-5.3531-4.1577,0-6.7042,3.5861-6.7042,10.1345,0,6.4445,2.5465,10.1345,6.7562,10.1345,3.3262,0,5.6649-2.2348,6.2366-5.9248h8.8351c-1.0393,9.3549-6.6523,14.5001-15.3315,14.5001-9.5107,0-15.5914-7.3279-15.5914-18.7097Z"
							fill="#3e3d40"
							className="#3e3d40 dark:fill-white"
						/>
						<path
							d="M420.3512,37.8708v.4158h-10.1864l-6.1846-14.4481c-.8835.1039-1.819.1559-2.8065.1559v14.2921h-8.991V1.9066h8.991v13.8763c6.8604-.1039,7.1201-5.0931,7.1201-13.8763h8.8872c.3638,8.5753-.8835,14.9678-4.9374,18.5538l8.1075,17.4104Z"
							fill="#3e3d40"
							className="#3e3d40 dark:fill-white"
						/>
						<path
							d="M443.1144,29.8152v8.4713h-19.0735V1.9066h8.991v27.9087h10.0825Z"
							fill="#3e3d40"
							className="#3e3d40 dark:fill-white"
						/>
						<path
							d="M446.7,1.9066h9.095v36.38h-9.095V1.9066Z"
							fill="#3e3d40"
							className="#3e3d40 dark:fill-white"
						/>
						<path
							d="M488.9005,1.9066v36.6399h-3.8458l-14.604-17.8262.2079,5.6129v11.9534h-8.8871V1.5947h3.8458l14.604,17.6183-.2079-6.2366V1.9066h8.8871Z"
							fill="#3e3d40"
							className="#3e3d40 dark:fill-white"
						/>
						<path
							d="M523.1493,37.8708v.4158h-10.1864l-6.1846-14.4481c-.8835.1039-1.819.1559-2.8065.1559v14.2921h-8.991V1.9066h8.991v13.8763c6.8604-.1039,7.1201-5.0931,7.1201-13.8763h8.8872c.3638,8.5753-.8835,14.9678-4.9374,18.5538l8.1075,17.4104Z"
							fill="#3e3d40"
							className="#3e3d40 dark:fill-white"
						/>
						<path
							d="M524.7604,26.8008h8.6272c0,2.3387,1.6111,3.69,4.3137,3.69,2.7544,0,4.3136-1.0914,4.3136-2.9104,0-2.3907-2.3907-3.0663-5.6129-3.69-7.5879-1.4552-11.1738-5.1451-11.1738-11.4857,0-6.4964,4.9893-11.0179,12.4211-11.0179,7.5359,0,12.4731,4.6774,12.4731,11.7975h-8.5753c0-2.2348-1.4032-3.5861-3.8978-3.5861-2.2349,0-3.5861,1.0394-3.5861,2.8065,0,1.2993.6237,2.7025,4.6774,3.5341,8.4194,1.715,12.2134,4.9893,12.2134,11.4857,0,6.8083-5.2491,11.3818-13.0968,11.3818-7.9517,0-13.0968-4.6774-13.0968-12.0054Z"
							fill="#3e3d40"
							className="#3e3d40 dark:fill-white"
						/>
					</g>
					<path
						d="M237.2933,14.8957c2.1258-.8536,4.5411.1779,5.3947,2.3037.8536,2.1264-.1779,4.5418-2.3037,5.3947-2.1258.8536-4.5411-.1779-5.3947-2.3037s.1779-4.5411,2.3037-5.3947Z"
						fill="#3e3d40"
						stroke="#221f20"
						strokeMiterlimit="10"
						strokeWidth=".25"
						className="#3e3d40 dark:stroke-white"
					/>
					<path
						d="M235.263,9.2918c5.2899-2.1242,11.3005.4427,13.4247,5.7326,2.1242,5.2916-.4427,11.3022-5.7326,13.4247-5.2899,2.1242-11.3005-.4427-13.4247-5.7326-2.1242-5.2899.4427-11.3005,5.7326-13.4247Z"
						fill="none"
						stroke="#3e3d40"
						strokeMiterlimit="10"
						className="#3e3d40 dark:stroke-white"
					/>
					<path
						d="M237.0445,14.2521c2.5106-1.0081,5.3632.2101,6.3713,2.7207,1.0081,2.5114-.2101,5.364-2.7207,6.3713-2.5106,1.0081-5.3632-.2101-6.3713-2.7207s.2101-5.3632,2.7207-6.3713Z"
						fill="none"
						stroke="#3e3d40"
						strokeMiterlimit="10"
						strokeWidth=".25"
						className="#3e3d40 dark:stroke-white"
					/>
					<path
						d="M236.8658,13.7633c2.7821-1.1172,5.9432.2328,7.0604,3.0149,1.1172,2.783-.2328,5.9441-3.0149,7.0604-2.7821,1.1172-5.9432-.2328-7.0604-3.0149s.2328-5.9432,3.0149-7.0604Z"
						fill="none"
						stroke="#3e3d40"
						strokeMiterlimit="10"
						strokeWidth=".25"
						className="#3e3d40 dark:stroke-white"
					/>
					<path
						d="M235.2948,13.2998c1.082-.4345,2.3113.0905,2.7458,1.1725.4345,1.0823-.0905,2.3117-1.1725,2.7458-1.082.4345-2.3113-.0905-2.7458-1.1725s.0905-2.3113,1.1725-2.7458Z"
						fill="#fff"
						stroke="#fff"
						strokeMiterlimit="10"
						strokeWidth=".25"
					/>
					<path
						d="M224.8015,14.1751c8.5913-7.5684,17.9811-8.1162,28.5526-.861"
						fill="none"
						stroke="#3e3d40"
						strokeMiterlimit="10"
						strokeWidth="2"
						className="#3e3d40 dark:stroke-white"
					/>
					<path
						d="M253.3541,25.1062c-7.4895,5.2598-20.2601,5.4026-28.5526.3194"
						fill="none"
						stroke="#3e3d40"
						strokeMiterlimit="10"
						strokeWidth="2"
						className="#3e3d40 dark:stroke-white"
					/>
				</svg>
			</Link>
		</div>
	)
}

function DesktopNav() {
	const user = useOptionalUser()
	const data = useRouteLoaderData<typeof loader>('root')
	return (
		<nav className="relative hidden lg:flex">
			{/* {links.map(({ href, label }) => (
				<PlusGridItem key={href} className="relative flex">
					<Link
						to={href}
						className="flex items-center px-4 py-3 text-base font-medium bg-blend-multiply hover:bg-black/[5%]"
					>
						{label}
					</Link>
				</PlusGridItem>
			))} */}

			<PlusGridItem className="relative flex">
				{user ? (
					<UserDropdown />
				) : (
					<Link
						to="/login"
						className="flex items-center px-4 py-3 text-base font-medium bg-blend-multiply hover:bg-black/[5%]"
					>
						Log In
					</Link>
				)}
			</PlusGridItem>
			<div className="relative ml-4 flex items-center">
				<ThemeSwitch userPreference={data?.requestInfo.userPrefs.theme} />
			</div>
		</nav>
	)
}

function UserDropdown() {
	const user = useUser()
	const submit = useSubmit()
	const formRef = useRef<HTMLFormElement>(null)
	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Link
					to={`/users/${user.username}`}
					// this is for progressive enhancement
					onClick={(e) => e.preventDefault()}
					className="flex items-center gap-2"
				>
					{/* <img
							className="h-8 w-8 rounded-full object-cover"
							alt={user.name ?? user.username}
							src={getUserImgSrc(user.image?.id)}
						/> */}
					<span className="px-4 py-3 text-base font-medium bg-blend-multiply hover:bg-black/[5%]">
						{user.name ?? user.username}
					</span>
				</Link>
			</DropdownMenuTrigger>
			<DropdownMenuPortal>
				<DropdownMenuContent sideOffset={1} align="start">
					{/* <DropdownMenuItem asChild>
						<Link prefetch="intent" to={`/users/${user.username}`}>
							<Icon className="text-body-md" name="avatar">
								Profile
							</Icon>
						</Link>
					</DropdownMenuItem> */}
					{/* <DropdownMenuItem asChild>
						<Link prefetch="intent" to={`/users/${user.username}/notes`}>
							<Icon className="text-body-md" name="pencil-2">
								Notes
							</Icon>
						</Link>
					</DropdownMenuItem> */}
					<DropdownMenuItem asChild>
						<Link prefetch="intent" to={`/dashboard`}>
							<Icon name="pencil-2">Dashboard</Icon>
						</Link>
					</DropdownMenuItem>
					<DropdownMenuItem
						asChild
						// this prevents the menu from closing before the form submission is completed
						onSelect={(event) => {
							event.preventDefault()
							submit(formRef.current)
						}}
					>
						<Form action="/logout" method="POST" ref={formRef}>
							<Icon name="exit">
								<button type="submit">Logout</button>
							</Icon>
						</Form>
					</DropdownMenuItem>
				</DropdownMenuContent>
			</DropdownMenuPortal>
		</DropdownMenu>
	)
}
